import { template } from "@ember/template-compiler";
import pageTitle from 'ember-page-title/helpers/page-title';
import RouteTemplate from 'ember-route-template';
import WrModal from 'ember-wiser/components/wr/modal';
import { concat } from '@ember/helper';
import type ApplicationController from 'parog-web/controllers/application';
import type { TOC } from '@ember/component/template-only';
interface Signature {
    Args: {
        controller: ApplicationController;
    };
}
const ApplicationTemplate: TOC<Signature> = template(`
  {{pageTitle (concat @controller.session.productName ' - Sêmeia')}}

  <WrModal id='login-modal' class='[&>:first-child]:bg-black/40' />
  <WrModal id='medics-web-modal' class='[&>:first-child]:bg-white/70' />

  {{outlet}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RouteTemplate(ApplicationTemplate);
